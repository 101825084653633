import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { CategoriesNavBar } from '../components/CategoriesNavBar'
import { CategoriesSwitch } from '../components/CategoriesSwitch'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { ProjectRow } from './components/ProjectRow'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const AssignCategoriesToProjectList = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [projectsList, setProjectsList] = useState([])
  const [count, setCount] = useState(20)
  const [order, setOrder] = useState('name')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [refresh, setRefresh] = useState(false)

  const { stateService } = useRouter()
  const isSafari = checkIsSafari()
  const styles = getStyles(variableColor, isSafari)

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'project',
      name: configurableNamesList?.project,
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '16px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'templates',
      name: 'Categories',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'left',
        width: '10%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
  ]

  useEffect(() => {
    const getProjectsList = async () => {
      const clientId = CurrentUser.getClientId()
      const { data } = await Api.get('projects', {
        application: stateService.params.app,
        client: clientId,
        deleted: false,
        is_active: true,
        order,
        page,
        search,
      })

      const { data: projectsWithCount } = await Api.get('app_projects', {
        app: stateService.params.app,
        deleted: false,
        projects: data?.results?.map((project) => project.id).join(','),
      })

      const listWithCategoriesCount = data.results.map((project) => {
        const projectObject = projectsWithCount?.results?.find(
          (objectProject) => objectProject.project.id === project.id,
        )

        project.categories_count = projectObject?.active_categories?.length

        return project
      })

      setCount(data?.count)

      setProjectsList(listWithCategoriesCount)
      setRefresh(false)
    }

    getProjectsList()
  }, [page, order, search, refresh])

  useEffect(() => {
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
      setProjectsList([])
    }
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [stateService.params])

  return (
    <div css={styles}>
      <CommonPageHeader headerText="Categories" />
      <CategoriesNavBar />
      <section className="categories__wrapper">
        <div className="categories__top">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
          />
          <CategoriesSwitch />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          <tbody>
            {projectsList?.map((project, index) => (
              <ProjectRow
                key={project?.id}
                project={project}
                index={index}
                search={search}
                page={page}
              />
            ))}
          </tbody>
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

const getStyles = (variableColor: string, isSafari: boolean) =>
  css({
    paddingBottom: '200px',
    '.categories__wrapper': {
      background: '#fff',
      marginLeft: '5px',
      paddingInline: '20px',
      width: 'fit-content',
    },
    '.categories__top': {
      display: 'flex',
      gap: '30px',
      paddingTop: '20px',
      paddingBottom: '10px',
    },
    '#search': {
      width: '264px',
    },
    table: {
      width: '783px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.project__row': {
      height: '40px',
      position: 'relative',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.projects__count': {
      margin: 0,
      width: '15px',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.name__cell': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      top: '40px',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '550px',
    },
    '.edit__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media(max-width: 1050px)': {
      '.categories__top': {
        flexDirection: 'column-reverse',
        gap: '17px',
      },
      '#search': {
        width: '264px',
      },
      '.templates__wrapper': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__cell': {
        maxWidth: '204px',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
