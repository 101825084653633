import { useEffect, useRef } from 'react'
import { css } from '@emotion/react'

interface GenericModalProps {
  setVisible: (visible: boolean) => void
  children: React.ReactNode
  styles?: any
}

export const GenericModal = ({
  setVisible,
  children,
  styles,
}: GenericModalProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false)
        document.body.style.overflow = 'scroll'
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    document.body.style.overflow = 'hidden'

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.body.style.overflow = 'scroll'
    }
  }, [setVisible])

  return (
    <div ref={ref} css={styles} className="modal-wrapper">
      {children}
    </div>
  )
}
