import manageIcon from '@/assets/icons/lms-manage.svg'
import { UISref } from '@/react/components/UISref'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import groupIcon from '@/assets/icons/lms-group.svg'
import assignIcon from '@/assets/icons/assign-icon.svg'
import { useEffect, useMemo, useState } from 'react'

export const CategoriesNavBar = () => {
  const { CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const role = CurrentUser.getRole()
  const [isGroupVisible, setGroupVisible] = useState(true)

  useEffect(() => {
    if (role === 'trainee_manager') {
      stateService.go('trainings.documentation')
    }

    if (role === 'supervisor') {
      setGroupVisible(false)
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        {/* <UISref
          to={'assessment.assessment_manage'}
          params={{
            clientId: clientId,
          }}
        >
          <a
            className={classNames('tab', {
              active:
                stateService.current.name ===
                  'assessment.assessment_score_key' ||
                stateService.current.name === 'assessment.assessment_manage' ||
                stateService.current.name === 'assessment.assessment_edit',
            })}
          >
            <img className="icon" src={manageIcon} alt="view icon" />
            <p>Manage</p>
          </a>
        </UISref> */}

        <UISref to={'app.categories.list'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.categories.list' ||
                stateService.current.name === 'app.categories.by_project' ||
                stateService.current.name === 'app.categories.assign',
            })}
          >
            <img className="icon" src={assignIcon} alt="add nav__icon" />
            <p>Assign</p>
          </a>
        </UISref>
        {role === 'supervisor' ? null : (
          <UISref to={'app.categories.groups'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'app.categories.groups' ||
                  stateService.current.name === 'app.categories.group_assign',
              })}
            >
              <img className="icon" src={groupIcon} alt="view nav__icon" />
              <p>Group</p>
            </a>
          </UISref>
        )}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  marginLeft: '5px',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    '.icon': {
      marginLeft: '25px',
      marginRight: '10px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: 0,
      marginRight: '25px',
      marginBottom: 0,
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
})
