import {
  AdvancedFilter,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  getFilterParam,
  useAngularServices,
} from '@/react/components'
import { ObservationNavBar } from '../components/ObservationNavBar'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import actionIcon from '@/assets/icons/action-icon.svg'
import { css } from '@emotion/react'
import { ObservationRow } from './components/ObservationRow'
import { ConfirmationHeaderModal } from '@/react/componentAssets/ConfirmationHeaderModal'
import { ObservationsSwitch } from '../components/ObservationsSwitch'
import { encodeString } from '@/utils/additionalEncodeLogic'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const ObservationsList = () => {
  const { stateService } = useRouter()

  const [page, setPage] = useState(stateService.params.pageNumber || 1)
  const [order, setOrder] = useState('-date_performed')
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [search, setSearch] = useState(stateService.params.search || '')
  const [observations, setObservations] = useState([])
  const [isAllChecked, setAllChecked] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [isDeleteButtonClicked, setDeleteButtonClicked] = useState(false)
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)

  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const [advancedFilters, setAdvancedFilters] = useState(
    getFilterStates(stateService),
  )
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isDeleteAvailable = useMemo(
    () => CurrentUser.getRole() === 'client_admin',
    [],
  )

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  useEffect(() => {
    if (observations.length) {
      setObservations([])
    }
    const getObservationList = async () => {
      const params = {
        application: stateService.params.app,
        order,
        page,
        search,
        table: true,
        role: true,
        tz_offset: '-120',
      }

      let stringParams = ''

      Object.entries(params).forEach((param) => {
        stringParams += param[0] + '=' + encodeString(param[1]) + '&'
      })

      Object.entries(advancedFilters).forEach((param) => {
        if (param[1].type && param[1].string) {
          stringParams +=
            getFilterParam(
              param[0],
              param[1].type,
              param[1].string,
              param[1].secondType,
              param[1].secondString,
            ) + '&'
        }
      })
      try {
        const { data: observationsRes } = await Api.get(
          'observations',
          stringParams.slice(0, -1),
        )
        setObservations(observationsRes.results)
        setCount(observationsRes.count)
        setRefresh(false)
      } catch {
        console.log('Failed to get observations')
      }
    }
    getObservationList()
  }, [order, page, search, refresh, advancedFilters])

  const handleAllChecked = () => {
    const observationIds = observations.map((ob) => ob?.id)
    const selectedToSet = !isAllChecked ? observationIds : []

    setAllChecked(!isAllChecked)
    setSelectedIds(selectedToSet)
  }

  const handleCombine = () => {
    stateService.go('app.batch_reports.finalize', {
      f_id: selectedIds.join(','),
    })
  }

  const handleDelete = async () => {
    setAllChecked(false)
    if (isAllChecked) {
      setPage(1)
    }

    const params = {
      ids: isAllChecked ? 'all' : selectedIds.join(','),
      search,
    }

    let stringParams = ''

    Object.entries(params).forEach((param) => {
      stringParams += param[0] + '=' + encodeString(param[1]) + '&'
    })

    Object.entries(advancedFilters).forEach((param) => {
      if (param[1].type && param[1].string) {
        stringParams +=
          getFilterParam(
            param[0],
            param[1].type,
            param[1].string,
            param[1].secondType,
            param[1].secondString,
          ) + '&'
      }
    })

    await Api.delete(`observations/bulk_delete`, stringParams.slice(0, -1))
    setSelectedIds([])
    setRefresh(true)
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        paddingLeft: '4px',
        minWidth: '32px',
        width: '32px',
        textAlign: 'left',
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
        '.header-actions': {
          display: 'flex',
          gap: '8px',
          position: 'relative',
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleAllChecked}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              {isDeleteAvailable && selectedIds.length ? (
                <div
                  className="action__text"
                  onClick={() => {
                    setActionsClicked(false)
                    setDeleteButtonClicked(true)
                  }}
                >
                  Delete
                </div>
              ) : null}
              {selectedIds.length >= 2 ? (
                <div className="action__text dark" onClick={handleCombine}>
                  Combine
                </div>
              ) : null}
            </div>
          )}
          {isDeleteButtonClicked && selectedIds.length && (
            <ConfirmationHeaderModal
              setModalVisable={setDeleteButtonClicked}
              message={`Delete ${isAllChecked ? count : selectedIds.length} ${
                selectedIds.length > 1 ? 'items' : 'item'
              }?`}
              handleClick={() => {
                setDeleteButtonClicked(false)
                handleDelete()
              }}
            />
          )}
          {isDeleteButtonClicked && !selectedIds.length && (
            <ConfirmationHeaderModal
              setModalVisable={setDeleteButtonClicked}
              message={'Please, choose items to delete'}
              handleClick={() => {
                setDeleteButtonClicked(false)
              }}
            />
          )}
        </div>
      ),
    },
    {
      className: 'table__header title__column',
      name: 'Title',
      type: 'title',
      additionalStyles: {
        minWidth: '249px',
        width: '249px',
        maxWidth: '136px',
        textAlign: 'left',
        paddingLeft: '14px !important',
        padding: 0,
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'title'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={() => {
            setPage(1)
          }}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Originator',
      type: 'user__first_name',
      additionalStyles: {
        minWidth: '136px',
        width: '136px',
        maxWidth: '136px',
        padding: 0,
        textAlign: 'left',
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={() => {
            setPage(1)
          }}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'user__company_name',
      additionalStyles: {
        minWidth: '172px',
        width: '172px',
        maxWidth: '172px',
        padding: 0,
        borderBottom: '2px solid rgb(198, 198, 198)',
        textAlign: 'left',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'company_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={() => {
            setPage(1)
          }}
        />
      ),
    },
    {
      className: 'table__header',
      name: configurableNamesList.projects_display,
      type: 'project__name',
      additionalStyles: {
        minWidth: '151px',
        width: '151px',
        maxWidth: '151px',
        padding: 0,
        textAlign: 'left',
        borderBottom: '2px solid rgb(198, 198, 198)',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '130px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'project_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={() => {
            setPage(1)
          }}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Performed',
      type: 'date_performed',
      filterHeader: true,
      additionalStyles: {
        borderBottom: '2px solid rgb(198, 198, 198)',
        minWidth: '100px',
        width: '100px',
        padding: 0,
        textAlign: 'left',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'View',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '2px solid rgb(198, 198, 198)',
        minWidth: '55px',
        width: '55px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Edit',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '2px solid rgb(198, 198, 198)',
        minWidth: '55px',
        width: '55px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Share',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '2px solid rgb(198, 198, 198)',
        minWidth: '55px',
        width: '55px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
  ]

  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)

  return (
    <section className="view-observations" css={baseStyle}>
      <CommonPageHeader
        headerText={configurableNamesList?.observations_display}
        isAnalyticsIconVisible={CurrentUser.getRole() === 'client_admin'}
        iconLink=" https://rtrspro.knowledgetransfer.us/#/workbooks/24/views"
      />
      <ObservationNavBar />
      <div className="observations-list">
        <div className="search__block">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
            extraFunction={() => {
              setAllChecked(false)
            }}
          />
          <ObservationsSwitch />
        </div>
        <div className="table__wrapper">
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          <tbody>
            {observations?.map((observation, index) => (
              <ObservationRow
                key={index}
                observation={observation}
                index={index}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                setAllChecked={setAllChecked}
                search={search}
                page={page}
              />
            ))}
          </tbody>
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

const getBaseStyle = (variableColor, isSafari) =>
  css({
    paddingBottom: '300px',
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    '.observations-list': {
      background: '#fff',
      paddingInline: '25px',
      width: 'fit-content',
    },
    '.search__block': {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',

      paddingTop: '20px',
      paddingBottom: '20px',
    },
    '#search': {
      width: '264px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.table__text': {
      maxHeight: '40px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: 0,
      textOverflow: 'ellipsis',
    },
    '.category': {
      maxWidth: '249px',
    },
    '.originator': {
      maxWidth: '136px',
    },
    '.company': {
      maxWidth: '172px',
    },
    '.project': {
      maxWidth: '151px',
    },
    '.created': {
      maxWidth: '120px',
    },
    '.view__row': {
      color: '#575757',
      position: 'relative',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.page-switcher-base': {
      paddingBottom: '10px',
    },
    '.checkmark__td': {
      paddingLeft: '4px',
    },
    '.title__td': {
      paddingLeft: '14px',
    },
    '.full__name': {
      display: !isSafari ? 'block' : 'none',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '80px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.title__fullname': {
      left: '50px',
    },
    '.template__fullname': {
      left: '300px',
    },
    '.owner__fullname': {
      left: '530px',
    },
    '.company__fullname': {
      left: '430px',
    },
    '.project__fullname': {
      left: '650px',
    },
    svg: {
      cursor: 'pointer',
    },
    '.icon__td': {
      textAlign: 'center',
      padding: 0,
      position: 'relative',
    },
    '.sucess__copy': {
      display: 'flex',
      gap: '4px',
      background: ' #FFF',
      paddingInline: '9px',
      paddingTop: '5px',
      paddingBottom: '5px',
      width: '153px',
      position: 'absolute',
      right: '45px',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.25)',
    },
    '.copy__message': {
      fontSize: '10px',
      margin: 0,
      fontWeight: 600,
      color: '#68696D',
    },
    '.share__td': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.action': {
      height: '17px',
      width: '17px',
      cursor: 'pointer',
      marginTop: '2px',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '@media(max-width: 1330px)': {
      '.observations-list': {
        width: '100%',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
      '.full__name': {
        display: 'none',
      },
    },
    '.alert-block': {
      right: '-90px !important',
    },
    '@media(max-width: 550px)': {
      '.search__block': {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
  })

function getFilterStates(stateService) {
  return {
    category_name: JSON.parse(stateService.params.category_name || '{}'),
    company_name: JSON.parse(stateService.params.company_name || '{}'),
    project_name: JSON.parse(stateService.params.project_name || '{}'),
    author_name: JSON.parse(stateService.params.author_name || '{}'),
  }
}
