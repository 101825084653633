import * as React from 'react'
import { css } from '@emotion/react'
import { debounce } from 'lodash'

export function Wildcard({
  wildcard,
  setWildcard,
  wildcardHeader,
  required,
  width,
}: {
  wildcard: string
  setWildcard: (value: string) => void
  wildcardHeader: string
  required: boolean
  width: string
}) {
  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )
  const textareaRef: React.RefObject<HTMLInputElement> = React.useRef(null)

  const debouncedTextChange = React.useRef(
    debounce(async (value) => {
      setWildcard(value)
    }, 1000),
  ).current

  const debouncedAdjust = React.useRef(
    debounce(async () => {
      adjustTextareaHeight()
    }, 500),
  ).current

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 20
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      // - 6 is a style compensations for this specific field
      setTextareaHeight(rows * lineHeight - 6)
    }
  }

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      adjustTextareaHeight()
    }
  }, [])

  return (
    <div css={baseStyle(width)}>
      <label>
        <b>{wildcardHeader}</b>
      </label>
      <div className="base__textarea">
        <textarea
          className="form__textarea"
          ref={textareaRef}
          onFocus={debouncedAdjust}
          onInput={debouncedAdjust}
          defaultValue={wildcard ? wildcard : ''}
          onChange={(value) => {
            debouncedTextChange(value.target.value)
          }}
          css={css({
            height: `${textareaHeight}px`,
            borderColor: required && wildcard.length === 0 ? 'red' : '#e5e6e7',
          })}
        />
      </div>
    </div>
  )
}

const baseStyle = (width: string) =>
  css({
    alignItems: 'center',
    width,
    '.base__textarea': {
      minWidth: '300px',
      maxWidth: '100%',
    },
    '.form__textarea': {
      width: '100%',
      backgroundColor: '#FFFFFF',
      outline: 'none',
      backgroundImage: 'none',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: 0,
      paddingLeft: '6px',
      paddingRight: '6px',
      paddingTop: '6px',
      paddingBottom: '6px',
      fontSize: '14px',
      lineHeight: '20px',
      maxWidth: '100%',
      resize: 'vertical',
    },
  })
