import React, { useEffect, useMemo, useState } from 'react'
import { MobileFormsNavBar } from '../commonComponents/MobileFormNavBar'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import moment from 'moment'
import { MenuItem, Select } from '@mui/material'
import InfoIcon from '@/assets/icons/info-icon.svg'
import { DateRangePicker } from 'react-date-range'
import { UISref } from '@/react/components/UISref'
import { InfoModal } from '@/react/componentAssets/InfoModal'

export const DownloadReportDataBase = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const { stateService } = useRouter()
  const [selectedFormId, setSelectedFormId] = useState(0)
  const [isPopUpVisible, setPopUpVisible] = useState(false)
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [dropdownForms, setDropdownForms] = useState([])
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [isMobile, setIsMobile] = useState(false)

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  useEffect(() => {
    const getDropdownForms = async () => {
      const clientId = CurrentUser.getClientId()
      const appId = stateService.params.app

      try {
        const { data } = await Api.getWithoutDeletedParam(
          'mobile_forms/drop_down',
          {
            application: appId,
            client_id: clientId,
            order: '-date_created',
          },
        )

        setDropdownForms(data)
      } catch {
        console.error('error')
      }
    }

    getDropdownForms()

    if (window.innerWidth < 1150) {
      setIsMobile(true)
    }
  }, [])

  const handleDateSelection = (date) => {
    const { selection } = date
    setRange([selection])
    setDateStart(moment(selection?.startDate).format('MMMM DD, YYYY'))
    setDateEnd(moment(selection?.endDate).format('MMMM DD, YYYY'))
  }
  const baseStyle = getBaseStyle(variableColor)
  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={`${configurableNamesList.mobile_form} Reports`}
      />
      <MobileFormsNavBar />
      <div className="reports__wrapper">
        <div className="first__container">
          <div className="dropdown__container">
            <div className="select__wrapper">
              <Select
                MenuProps={{
                  sx: {
                    '&& .Mui-selected': {
                      backgroundColor: variableColor,
                      '& p': {
                        color: '#fff',
                      },
                    },
                  },
                }}
                style={{
                  width: isMobile ? '260px' : '400px',
                  height: '30px',
                  borderRadius: '0px',
                }}
                sx={{
                  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1px solid #CCC',
                    },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      border: '1px solid #CCC',
                    },
                  '&..Mui-selected': {
                    backgroundColor: variableColor,
                  },
                  '& legend': { display: 'none' },
                }}
                value={selectedFormId}
                onChange={(e) => {
                  setSelectedFormId(e.target.value)
                }}
              >
                <MenuItem value={0}>
                  <div
                    className="dropdown__option"
                    css={{
                      display: 'flex',
                      width: isMobile ? '200px' : '350px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '25px',
                    }}
                  >
                    <p
                      className="dropdown__name"
                      css={{
                        margin: 0,
                        color: '#888',
                        fontSize: '12px',
                        fontWeight: 400,
                      }}
                    >
                      {`Select ${configurableNamesList.mobile_form} to Download Data`}
                    </p>
                  </div>
                </MenuItem>
                {dropdownForms.map((form, index) => (
                  <MenuItem
                    key={index}
                    value={form.id}
                    sx={{
                      backgroundColor: index % 2 === 0 ? '#F2F3F5' : '#fff',
                      borderTop: index % 2 === 0 ? '1px solid #E5E6E7' : '',
                      borderBottom: index % 2 === 0 ? '1px solid #E5E6E7' : '',
                    }}
                  >
                    <div
                      className="dropdown__option"
                      css={{
                        display: 'flex',
                        width: '350px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '25px',
                      }}
                    >
                      <p
                        className="dropdown__name"
                        css={{
                          margin: 0,
                          color: '#676A6C',
                          fontSize: '12px',
                          fontWeight: 600,
                          maxWidth: '250px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {form.name}
                      </p>
                      <p
                        className="dropdown__date"
                        css={{
                          margin: 0,
                          color: '#676A6C',
                          fontSize: '12px',
                          fontWeight: 400,
                        }}
                      >
                        {form.date_created}
                      </p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              <div>
                <img
                  className="info__icon"
                  src={InfoIcon}
                  alt="I"
                  onClick={() => {
                    setPopUpVisible(true)
                  }}
                />
                {isPopUpVisible ? (
                  <InfoModal
                    message="Each template is saved separately based on the time and date it was modified."
                    setModalOpen={setPopUpVisible}
                    top="155px"
                    left="270px"
                  />
                ) : null}
              </div>
            </div>
          </div>
          {selectedFormId && !isMobile ? (
            <UISref
              to="app.forms.reports_export_list"
              params={{
                formId: selectedFormId,
                startDate: dateStart
                  ? moment(range[0].startDate).format('MM/DD/YYYY')
                  : '',
                endDate: dateEnd
                  ? moment(range[0].endDate).format('MM/DD/YYYY')
                  : '',
              }}
            >
              <a className="next__button">Next</a>
            </UISref>
          ) : null}
          {!selectedFormId && !isMobile ? (
            <ButtonElement disabled={true} text="Next" />
          ) : null}
        </div>
        <p className="calendar__title">
          {dateStart && dateEnd ? (
            <div
              className="calendar__button"
              onClick={() => {
                setCalendarVisible(!isCalendarVisible)
              }}
            >
              {`${dateStart} - ${dateEnd}`}
            </div>
          ) : (
            <div
              className="calendar__button"
              onClick={() => {
                setCalendarVisible(!isCalendarVisible)
              }}
            >
              Select date range
            </div>
          )}
          &nbsp;or leave blank to select all available reports.
        </p>
        {selectedFormId && isMobile ? (
          <div className="button__wrapper">
            <UISref
              to="app.forms.reports_export_list"
              params={{
                formId: selectedFormId,
                startDate: dateStart
                  ? moment(range[0].startDate).format('MM/DD/YYYY')
                  : '',
                endDate: dateEnd
                  ? moment(range[0].endDate).format('MM/DD/YYYY')
                  : '',
              }}
            >
              <a className="next__button">Next</a>
            </UISref>
          </div>
        ) : null}
        {!selectedFormId && isMobile ? (
          <div className="button__wrapper">
            <ButtonElement disabled={true} text="Next" />
          </div>
        ) : null}
        {isCalendarVisible && (
          <DateRangePicker
            onChange={(item) => {
              handleDateSelection(item)
            }}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={range}
            direction="horizontal"
            color={variableColor}
            rangeColors={variableColor}
          />
        )}
      </div>
    </section>
  )
}

function getBaseStyle(variableColor: string) {
  return css({
    '@media(max-width: 1150px)': {
      '.reports__wrapper': {
        width: '100% !important',
      },
      '.calendar__title': {
        fontSize: '11px',
        paddingLeft: '10px !important',
      },
      '.rdrDefinedRangesWrapper': {
        width: '90px',
      },
      '.rdrCalendarWrapper': {
        width: '225px',
      },
      '.button__wrapper': {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '12px',
        paddingBottom: '12px',
      },
      '.rdrStaticRangeLabel': {
        padding: '10px 5px',
      },
      '.rdrInputRange': {
        padding: '10px 5px',
      },
    },
    '.reports__wrapper': {
      background: '#fff',
      width: '60%',
    },
    '.first__container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingInline: '25px',
      paddingTop: '20px',
    },
    '.dropdown__container': {
      width: '400px',
    },
    '.select__wrapper': {
      display: 'flex',
      gap: '5px',
    },
    '.info__icon': {
      display: 'flex',
      position: 'relative',
      alignSelf: 'flex-start',
      cursor: 'pointer',
    },
    '.next__button': {
      padding: '3px 5px',
      paddingInline: '25px',
      border: variableColor,
      backgroundColor: variableColor,
      color: '#fff',
      fontSize: '12px',
      minWidth: '75px',
    },
    '.calendar__title': {
      display: 'flex',
      margin: 0,
      paddingLeft: '25px',
      marginTop: '20px',
      paddingBottom: '27px',
    },
    '.calendar__button': {
      cursor: 'pointer',
      color: variableColor,
    },
    '.rdrDateDisplayWrapper': {
      background: '#fff',
    },
    '.rdrStaticRangeSelected': {
      color: `${variableColor} !important`,
    },
    '.rdrDateDisplay': {
      color: `${variableColor} !important`,
    },
  })
}
