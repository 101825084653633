import {
  ButtonElement,
  CommonPageHeader,
  DateInputField,
  useAngularServices,
} from '@/react/components'
import { ObservationNavBar } from '../components/ObservationNavBar'
import { SelectWithSearch } from '../../components/SelectWithSearch'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'
import { CalendarDatePicker } from '@components/CalendarDatePicker'
import moment from 'moment'
import { DataWrap, GenericAppProject, PaginatedGet } from '@/react/types'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'
import { InfoModal } from '@/react/componentAssets/InfoModal'

export const AddObservation = () => {
  const { CurrentUser, Api, $rootScope, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const [projectPage, setProjectPage] = useState(1)
  const [projects, setProjects] = useState([])
  const [project, setProject] = useState('')
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [title, setTitle] = useState('')
  const [isMaxModalVisible, setMaxModalVisible] = useState(false)
  const [isMaxThresholdMessage, setMaxThresholdMessage] = useState(false)

  const today = new Date()
  const [datePerformed, setDatePerformed] = useState(today)

  const isMobile = window.innerWidth < 1080

  const configurableProjectTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).projects_display
  }, [$rootScope])

  const configurableObservationsTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).observations_display
  }, [$rootScope])

  useEffect(() => {
    const getProjectOptions = async () => {
      const clientId = CurrentUser.getClientId()

      const { data: projectsFromServer } = await Api.get('projects', {
        assigned: true,
        client: clientId,
        deleted: false,
        is_active: 'True',
        page: projectPage,
      })

      if (projectsFromServer.next) {
        setProjectPage(projectPage + 1)
      }

      setProjects((prevOptions) => [
        ...prevOptions,
        ...projectsFromServer.results,
      ])
    }
    getProjectOptions()
  }, [projectPage])

  const handleNextButton = async () => {
    const selectedProject = projects.find((p) => p.name === project)

    setButtonDisabled(true)

    const {
      data: projectResponse,
    }: DataWrap<PaginatedGet<GenericAppProject[]>> = await Api.get(
      'app_projects',
      {
        app: stateService.params.app,
        project: selectedProject?.id,
      },
    )

    if (!projectResponse?.results[0]?.active_categories?.length) {
      setMaxModalVisible(true)
    }

    if (!projectResponse.results.length) {
      await Api.post('app_projects', {
        app: stateService.params.app,
        client: CurrentUser.getClientId(),
        project: selectedProject?.id,
      })
    }

    const formattedDate = moment(datePerformed).format(
      'YYYY-MM-DDTHH:mm:ss.SSSSSSZZ',
    )
    try {
      const { data: createdObservation } = await Api.post('observations', {
        application: stateService.params.app,
        title: title,
        project: selectedProject?.id,
        date_performed: formattedDate,
      })

      stateService.go('app.observations.edit', {
        observation: createdObservation?.id,
      })
    } catch (error) {
      Notification.clearNotification()

      if (error.status === 400) {
        setMaxThresholdMessage(
          `${error.data.detail[0]} Please contact your Administrator.`,
        )
      }
    }
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={configurableObservationsTitle} />
      <ObservationNavBar />
      <main className="add__wrapper">
        <div className="top__section">
          <h4 className="section__header">Submit Report</h4>
          <ButtonElement
            text="Next"
            disabled={!project || !datePerformed || isButtonDisabled}
            functionToTrigger={handleNextButton}
            buttonType="submit"
          />
        </div>
        <div className="blocks__wrapper">
          <div className="left__block">
            <SelectWithSearch
              value={project}
              setValue={setProject}
              options={projects}
              title={configurableProjectTitle}
              resetFunction={() => {
                setButtonDisabled(false)
              }}
            />
            {isMaxModalVisible && (
              <InfoModal
                top="0px"
                right="-115px"
                message={`No categories assigned to ${project}`}
                setModalOpen={setMaxModalVisible}
              />
            )}

            {isMaxThresholdMessage && (
              <InfoModal
                top="0px"
                right="-115px"
                message={isMaxThresholdMessage}
                setModalOpen={setMaxThresholdMessage}
              />
            )}

            {isMobile ? (
              <div className="input__block">
                <p className="input__title">Title</p>
                <AutoExpandTextArea
                  className="title__input"
                  value={title}
                  handleChange={(e) => {
                    setTitle(e.target.value)
                  }}
                  placeholder="Optional"
                />
              </div>
            ) : null}

            <div className="date__wrapper">
              <p className="input__title">Date Performed</p>
              <DateInputField
                width="187px"
                ohChange={(value) => {
                  setDatePerformed(value)
                }}
                defDate={moment(datePerformed)}
                maxDate={moment(today)}
              />
            </div>
          </div>

          {!isMobile ? (
            <div>
              <div className="input__block">
                <p className="input__title">Title</p>
                <AutoExpandTextArea
                  className="title__input"
                  value={title}
                  handleChange={(e) => {
                    setTitle(e.target.value)
                  }}
                  placeholder="Optional"
                />
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </section>
  )
}

const baseStyle = css({
  main: {
    background: '#fff',
    width: '835px',
  },
  '.left__block': {
    position: 'relative',
  },
  '.select__title': {
    color: '#575757',
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  '.input__title': {
    color: '#575757',
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  '.title__input': {
    ...TextInputStyle,
    width: '380px',
    height: '32px',
    borderColor: '#E6E6E6',
    color: '#000',
    paddingLeft: '16px',
    fontSize: '13px',
    '::placeholder': {
      color: '#B3B3B3',
      fontWeight: 400,
    },
  },
  '.top__section': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
    paddingInline: '26px',
    paddingBottom: '12px',
    borderBottom: '1px solid #EEEFF3',
  },
  '.select__section': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    paddingTop: '21px',
    paddingInline: '26px',
    paddingBottom: '50px',
  },
  '.clearButton': {
    display: 'none',
  },
  '.blocks__wrapper': {
    display: 'flex',
    gap: '21px',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  '.date__wrapper': {
    paddingTop: '21px',
  },
  '@media(max-width: 1080px)': {
    main: {
      width: '100%',
    },
    '.select__section': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.left__block': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.input__block': {
      paddingTop: '21px',
    },
  },
})
